<template>
  <a-select
    :show-search="showSearch"
    v-model="selected"
    :disabled="disabled"
    :label-in-value="true"
    placeholder="请选择设备网口"
    @change="change"
    :allow-clear="false"
    :filter-option="false"
    @popupScroll="popupScroll"
    @search="search"
    @select="select"
    :loading="loading"
  >
    <a-select-option
      v-for="item in dataSource"
      :key="item.id"
      :disabled="item.disabled"
    >
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'NetworkInterfaceSelect',
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: '网口'
    },
    value: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    form: {
      type: Object
    },
    nodeType: {
      type: String
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: undefined,
      timer: undefined
    }
  },
  mounted () {
    // {label: '', key: ''}
    if (this.value) this.selected = this.value
    else if (this.dataSource && this.dataSource.length) {
      this.selected = this.dataSource[0]
    }
  },
  methods: {
    change (value) {
      this.$emit('input', {
        label: value.label,
        value: value.key
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop <= clientHeight + 10) {
        if (this.total > this.form.page * this.form.page_size) {
          this.$emit('popupScroll', this.nodeType)
        }
      }
    },
    search (value) {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('search', [value, this.nodeType])
      }, 500)
    },
    select (v) {
      // this.$emit('clearSearch')
    },
    focus () {
      this.$emit('focus')
    }
  },
  watch: {
    value: {
      handler (v, ov) {
        this.selected = v
      },
      deep: true
    }
  }
}
</script>
