<template>
  <a-layout class="network-topology-container">
    <!-- 顶部工具栏 -->
    <a-layout-header
      style="
        height: 52px;
        line-height: 52px;
        background: #fff;
        padding: 0px 16px 0 8px;
        color: #096dd9;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        z-index: 5;
      "
    >
      <div class="name-container">
        <a-icon
          type="left"
          @click="$router.push({ name: 'NetworkTopologyList' })"
          style="font-size: 18px; margin-right: 8px"
        />
        <a-tooltip :title="detail.name">
          <span>{{ detail.name }}</span>
        </a-tooltip>
      </div>
      <a-tooltip title="保存">
        <a-button type="link" @click="save"
          ><a-icon type="save" theme="filled" class="icon"
        /></a-button>
      </a-tooltip>
      <a-tooltip title="清空">
        <a-button type="link" @click="clearGraph"
          ><a-icon type="rest" theme="filled" class="icon"
        /></a-button>
      </a-tooltip>
      <a-tooltip title="连线">
        <a-button
          type="link"
          @click="createLine"
          style="padding: 0 8px; margin: 0 6px;"
          :class="canLine ? 'normal-button' : 'disabled-button'"
          ><a-icon type="line" class="icon"
        /></a-button>
      </a-tooltip>
      <a-tooltip title="自动布局">
        <a-button type="link" @click="autoLayout">
          <a-icon type="layout" class="icon" />
        </a-button>
      </a-tooltip>

      <div style="float: right; line-height: 52px">
        <a-space :size="12">
          <div style="line-height: 52px">
            <a-icon type="zoom-out" @click="zoomOut" class="icon" />
          </div>
          <div style="line-height: 52px; margin: 0 0 0 0">
            <a-icon type="zoom-in" @click="zoomIn" class="icon" />
          </div>
        </a-space>
      </div>
      <div style="position: absolute; bottom: 8px; right: 28px">
        <a-space size="large">
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_RED,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>有告警 / 流量超过90%</div>
            </a-space>
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_YELLOW,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>流量低于90%</div>
            </a-space>
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_GREEN,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>流量低于60%</div>
            </a-space>
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_GRAY,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>无数据</div>
            </a-space>
          </a-space>
      </div>
    </a-layout-header>
    <!-- 内容区 -->
    <a-layout-content
      id="container"
      style="
        display: flex;
        background: #fff;
        min-height: calc(100vh - 134px);
        z-index: 4;
        position: relative;
      "
    >
      <div id="stencil" :style="{ width: readOnly ? '0' : '218px' }"></div>
      <div class="stencil-btn" :style="{ left: readOnly ? '0' : '218px' }">
        <a-button type="link" @click="readOnly = !readOnly">
          <a-icon v-if="!readOnly" type="double-left" />
          <a-icon v-else type="double-right" />
        </a-button>
      </div>
      <div id="topology" style="flex: 1">图</div>
    </a-layout-content>

    <source-select-modal
      ref="sourceSelect"
      @ok="addNodes"
    ></source-select-modal>
    <edge-form
      ref="edgeForm"
      @ok="info => networkTopologyAddEdge(graph, info)"
    ></edge-form>

    <edge-infos ref="edgeInfoDrawer"></edge-infos>
    <network-device-drawer ref="networkDeviceDrawer"></network-device-drawer>
    <server-drawer ref="serverDrawer"></server-drawer>
    <storage-drawer ref="storageDrawer"></storage-drawer>
    <hypervisor-drawer ref="hypervisorDrawer"></hypervisor-drawer>
  </a-layout>
</template>

<script>
import { Addon, Graph, Shape } from '@antv/x6'
import {
  getNetworkTopology,
  updateNetworkTopology
} from '@/api/network-topology.js'
import { networkDeviceTypes } from '@/utils/const'
import {
  networkTopologyAddNodes,
  networkTopologyEdgeAttrs,
  networkTopologyAddEdge,
  getConnectEdges,
  initLayout,
  initNodeStyle,
  initEdges,
  EDGE_GRAY,
  EDGE_RED,
  EDGE_YELLOW,
  EDGE_GREEN
} from '@/utils/networkTopology'
import { createNetworkNode } from '@/utils/topology'
import SourceSelectModal from '@/components/modal/SourceSelectModal'
import EdgeForm from './modules/EdgeForm'
import EdgeInfos from './modules/EdgeInfos'

const dagreLayout = initLayout()

export default {
  name: 'NetworkTopologyDetail',
  data () {
    return {
      id: this.$route.params.id,
      graph: null,
      stencil: null,
      readOnly: false,
      displayInfo: true,
      detail: {
        id: '',
        name: '',
        nodes: [],
        edges: []
      },
      networkDeviceTypes,
      networkTopologyAddEdge,
      // 操作区状态
      canLine: false,
      canUndo: true,
      canRedo: true,
      scale: 100,
      infoDisplayType: 'edgeInfo',
      canOperate: false,
      EDGE_GRAY,
      EDGE_RED,
      EDGE_YELLOW,
      EDGE_GREEN
    }
  },
  components: {
    SourceSelectModal,
    EdgeForm,
    EdgeInfos,
    NetworkDeviceDrawer: () =>
      import('@/components/drawer/NetworkDeviceDrawer'),
    StorageDrawer: () => import('@/components/drawer/StorageDrawer'),
    ServerDrawer: () => import('@/components/drawer/ServerDrawer'),
    HypervisorDrawer: () => import('@/components/drawer/HypervisorDrawer')
  },
  mounted () {
    this.initGraph()
    this.initStencil()
  },
  beforeDestroy () {
    if (this.graph) this.graph.dispose()
  },
  methods: {
    fetch () {
      this.canOperate = false
      getNetworkTopology(this.id).then(async res => {
        this.detail = res.data
        await initNodeStyle(this.detail.nodes, this)
        this.graph.fromJSON({ nodes: this.detail.nodes })
        this.graph.zoomToFit({ maxScale: 1.2, minScale: 0.6, padding: 30 })
        initEdges(this.graph, this.detail.edges).then(() => {

        }).finally(() => {
          this.canOperate = true
        })
      })
    },
    initGraph () {
      const container = document.getElementById('topology')
      this.graph = new Graph({
        container,
        autoResize: true,
        panning: false,
        scroller: true,
        grid: true,
        embedding: false,
        resizing: false,
        snapline: true,
        connecting: {
          snap: {
            radius: 20 // 自动吸附
          },
          allowBlank: false,
          allowMulti: 'withPort',
          allowLoop: false,
          allowNode: false,
          allowEdge: false,
          createEdge () {
            return new Shape.Edge({
              ...networkTopologyEdgeAttrs,
              visible: true
            })
          },
          validateEdge: ({ edge }) => {
            const sourceNode = edge.getSourceNode()
            const targetNode = edge.getTargetNode()

            this.$refs.edgeForm.show(edge, sourceNode, targetNode, this.graph)
            return false
          }
        },
        keyboard: true
      })
      this.graph.on('edge:click', ({ edge }) => {
        if (this.canOperate) {
          this.$refs.edgeInfoDrawer.show(
            getConnectEdges(this.graph, edge),
            edge.getSourceNode(),
            edge.getTargetNode(),
            this.graph
          )
        }
      })

      this.graph.on('edge:mouseenter', ({ edge }) => {
        edge.attr('line/strokeDasharray', 0)
      })

      this.graph.on('edge:mouseleave', ({ edge }) => {
        edge.attr('line/strokeDasharray', 8)
      })

      this.graph.on('node:mouseenter', ({ node }) => {
        if (this.canOperate) {
          node.addTools({
            name: 'button-remove',
            args: {
              x: 0,
              y: 0,
              offset: { x: 10, y: 4 },
              onClick: ({ cell }) => {
              // 删除节点之前 与该节点有连线的所有节点删除连接的port
                const connectEdges = this.graph.getConnectedEdges(cell).filter(edge => !edge.visible)
                connectEdges.forEach(edge => {
                  const sNode = edge.getSourceNode()
                  const tNode = edge.getTargetNode()
                  const source = edge.getSource()
                  const target = edge.getTarget()
                  if (source.port && source.cell !== cell.id) {
                    sNode.removePort(source.port)
                  }
                  if (target.port && target.cell !== cell.id) {
                    tNode.removePort(target.port)
                  }
                })
                this.graph.removeNode(cell)
              }
            }
          })
        }
      })

      this.graph.on('node:mouseleave', ({ node }) => {
        node.removeTools()
      })
      this.graph.on('node:click', ({ e, node }) => {
        if (this.canOperate) {
          e.stopPropagation()
          if (node.data.sourceId) {
            const data = node.data
            if (data.sourceType === 'network_device') {
              this.$refs.networkDeviceDrawer.show(data.sourceId)
            } else if (data.sourceType === 'storage') {
              this.$refs.storageDrawer.show(data.sourceId)
            } else if (data.sourceType === 'hypervisor') {
              this.$refs.hypervisorDrawer.show(data.sourceId)
            } else {
              this.$refs.serverDrawer.show(data.sourceId)
            }
          }
        }
      })

      this.fetch()
    },
    initStencil () {
      this.stencil = new Addon.Stencil({
        target: this.graph,
        title: false,
        stencilGraphWidth: 218,
        groups: [
          {
            name: 'network_device',
            title: '网络设备',
            graphHeight: 240
          },
          {
            name: 'storage',
            title: '存储',
            graphHeight: 100
          },
          {
            name: 'server',
            title: '物理机',
            graphHeight: 100
          },
          {
            name: 'hypervisor',
            title: '虚拟机',
            graphHeight: 100
          }
        ],
        search: false,
        layoutOptions: {
          columns: 3,
          columnWidth: 'auto',
          rowHeight: 70
        },
        validateNode: node => {
          if (node.shape === 'vue-shape') {
            this.$refs.sourceSelect.show(node)
            return false
          }
          return false
        }
      })

      const networkNodes = []
      for (let i = 0; i < this.networkDeviceTypes.length; i++) {
        const device = this.networkDeviceTypes[i]
        networkNodes.push(
          createNetworkNode(
            this.graph,
            '',
            'network_device',
            '',
            device,
            this.$t(`network_device_type.${device}`)
          )
        )
      }

      this.stencil.load(networkNodes, 'network_device')
      this.stencil.load(
        [createNetworkNode(this.graph, '', 'storage', '', 'storage', '存储')],
        'storage'
      )
      this.stencil.load(
        [createNetworkNode(this.graph, '', 'server', '', 'server', '物理机')],
        'server'
      )
      this.stencil.load(
        [createNetworkNode(this.graph, '', 'hypervisor', '', 'hypervisor', '虚拟机')],
        'hypervisor'
      )

      document.getElementById('stencil').appendChild(this.stencil.container)
    },
    addNodes (nodes) {
      let newNodes = []
      const graphNodes = this.graph.getNodes()

      if (graphNodes.length) {
        nodes.forEach(item => {
          const newSourceId = item.data.sourceId
          const index = graphNodes.findIndex(
            e => e.data.sourceId === newSourceId
          )
          if (index === -1) newNodes.push(item)
        })
      } else newNodes = nodes
      networkTopologyAddNodes(this.graph, newNodes, this.canLine)
    },
    save () {
      // 先去掉临时节点
      const nodes = this.graph.getNodes()
      if (this.canLine) {
        // 判断
        nodes.forEach(node => {
          const ports = node.getPortsByGroup('temporary')
          node.removePorts(ports)
        })
      }
      const data = {
        nodes: this.graph.toJSON().cells.filter(node => node.shape !== 'edge'),
        edges: this.graph.toJSON().cells.filter(node => node.shape === 'edge')
      }
      updateNetworkTopology(this.detail.id, {
        nodes: data.nodes,
        edges: data.edges,
        modified: true
      }).then(res => {
        this.$message.success(res.message)
      })
    },
    // 允许创建连线
    createLine () {
      this.canLine = !this.canLine
      const nodes = this.graph.getNodes()
      if (this.canLine) {
        // 判断
        nodes.forEach(node => {
          node.addPort({ group: 'temporary', id: 'temporary' })
        })
      } else {
        nodes.forEach(node => {
          const ports = node.getPortsByGroup('temporary')
          node.removePorts(ports)
        })
      }
    },
    getContainer () {
      return document.querySelector('#container')
    },
    // 自动布局
    autoLayout () {
      this.graph.startBatch('autoLayout')
      const oldNodeList = this.graph.getNodes()
      const nodes = []
      const edges = []
      this.graph.toJSON().cells.forEach(item => {
        if (item.shape === 'edge') {
          edges.push(item)
        } else nodes.push(item)
      })
      const model = dagreLayout.layout({ nodes, edges })
      model.nodes.forEach(newNode => {
        const node = oldNodeList.find(oldNode => oldNode.id === newNode.id)
        if (node) node.position(newNode.x, newNode.y)
      })
      this.graph.zoomToFit({ maxScale: 1.2, minScale: 0.6 })
      this.graph.stopBatch('autoLayout')
    },
    // 清空画布/删除所选
    clearGraph () {
      this.graph.clearCells()
    },
    zoomIn () {
      this.graph.zoom(0.1)
    },
    zoomOut () {
      this.graph.zoom(-0.1)
    }
  }
}
</script>

<style lang="less">
.network-topology-container {
  background: #ffffff;
  position: relative;
  z-index: 10;

  .name-container {
    font-weight: 600;
    font-size: 18px;
    width: 240px;
    overflow: hidden;
    line-height: 52px;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .disabled-button {
    color: #878787;
    background: #f5f5f5;
  }

  .normal-button {
    color: #ffffff;
    background: #096dd9;
  }

  #stencil {
    transition: all 0.3s;
    .x6-widget-stencil {
      background: #ffffff;
      width: inherit;
      top: 0;
      border-right: 1px solid #e3e3e3;

      & > .x6-widget-stencil-title {
        display: none;
      }

      .x6-widget-stencil-group-title {
        background: #f0f2f5;
      }
    }
  }
  .stencil-btn {
    position: absolute;
    top: 70px;
    width: 22px;
    height: 34px;
    background: rgb(255, 255, 255);
    border-radius: 0 8px 8px 0;
    border: 1px solid #e3e3e3;
    border-left: none;
    text-align: center;
    z-index: 99;
    transition: all 0.3s;
    & > .ant-btn-link {
      padding: 0;
    }
  }

  #topology {
    height: calc(100vh - 134px);
    background: #ffffff30;
    flex: 1;
  }

  .topology-info {
    border-left: 1px solid #e3e3e3;
  }

  @keyframes topology-edge {
    to {
      stroke-dashoffset: -1000;
    }
  }
}
</style>
