<template>
  <a-modal
    v-if="sourceNode && targetNode"
    title="链路创建"
    :visible="visible"
    :width="720"
    :destroy-on-close="true"
    @ok="ok"
    @cancel="reset"
  >
    <a-spin :spinning="spinning">
      <a-alert message="不选择网口默认连接到设备上。" banner type="info" style="border-radius: 8px; margin-bottom: 16px;"/>
      <a-form-model
        ref="form"
        :label-col="{ span: 3, style: { textAlign: 'left' } }"
        :model="form"
        :wrapper-col="{ span: 21 }"
      >
        <a-form-model-item label="网口1" prop="sourcePort" style="margin-bottom: 8px;" >
          <network-interface-select
            v-model="form.sourcePort"
            :dataSource="sourcePortData"
            nodeType="source"
            @popupScroll="popupScroll('source')"
            :form="sourceParams"
            :total="sourcePortTotal"
            :showSearch="true"
            @search="handleSearch"
            :loading="sourceSelectLoading"
            @clearSearch="
              () => {
                sourceParams = {
                  page: 1,
                  page_size: 10
                }
                fetchSourcePorts()
                sourcePortData = []
              }
            "
          ></network-interface-select>
          <div style="font-size: 12px; color: #585858; margin: -6px 0 0 2px;">设备名称：{{ sourceNode.data.name }}</div>
        </a-form-model-item>

        <a-form-model-item label="网口2" prop="targetPort" style="margin-bottom: 8px;" >
          <network-interface-select
            v-model="form.targetPort"
            :dataSource="targetPortData"
            nodeType="target"
            @popupScroll="popupScroll('target')"
            :form="targetParams"
            :total="targetPortTotal"
            :showSearch="true"
            @search="handleSearch"
            :loading="targetSelectLoading"
            @clearSearch="
              () => {
                targetParams = {
                  page: 1,
                  page_size: 10
                }
                fetchTargetPorts()
                targetPortData = []
              }
            "
          ></network-interface-select>
          <div style="font-size: 12px; color: #585858; margin: -6px 0 0 2px;">设备名称：{{ targetNode.data.name }}</div>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { getNetworkDeviceNetworkInterfaceList } from '@/api/network-device'
import { getServerInterfaceList } from '@/api/server'
import { getStorageInterfaceList } from '@/api/storage'
// import { getHypervisorInterfaceList } from '@/api/hypervisor'
import NetworkInterfaceSelect from '@/components/select/NetworkInterfaceSelect'

export default {
  name: 'EdgeForm',
  components: {
    NetworkInterfaceSelect
  },
  data () {
    return {
      spinning: false,
      visible: false,
      form: {
        sourcePort: undefined,
        targetPort: undefined
      },
      params: {
        page: 1,
        page_size: 10
      },
      sourcePortData: [], // select数据
      targetPortData: [], // select数据
      sourceNode: undefined,
      targetNode: undefined,
      graph: undefined,
      sourceParams: {
        page: 1,
        page_size: 10
      },
      targetParams: {
        page: 1,
        page_size: 10
      },
      sourcePortTotal: 0,
      targetPortTotal: 0,
      sourceUsagePorts: [], // node已用port
      targetUsagePorts: [], // node已用port
      sourceGroup: '',
      targetGroup: '',
      sourceSelectLoading: false,
      targetSelectLoading: false,
      edge: undefined
    }
  },
  methods: {
    show (edge, sourceNode, targetNode, graph) {
      this.visible = true
      this.graph = graph
      this.sourceNode = sourceNode
      this.targetNode = targetNode
      this.edge = edge

      // 获取当前节点的已使用ports
      this.sourceUsagePorts = this.sourceNode.getPorts()
      this.targetUsagePorts = this.targetNode.getPorts()

      // 加载第一次数据
      this.fetchSourcePorts()
      this.fetchTargetPorts()
    },
    ok () {
      const portNames = { sourcePortName: '', targetPortName: '' }
      const source = { cell: this.sourceNode.id }
      if (this.form.sourcePort) {
        source.port = this.form.sourcePort.value
        portNames.sourcePortName = this.form.sourcePort.label
      }
      const target = { cell: this.targetNode.id }
      if (this.form.targetPort) {
        target.port = this.form.targetPort.value
        portNames.targetPortName = this.form.targetPort.label
      }
      this.$emit('ok', {
        edge: [source, target],
        nodes: [this.sourceNode, this.targetNode],
        portNames
      })
      this.reset()
    },
    reset () {
      this.form = {
        sourcePort: undefined,
        targetPort: undefined
      }
      this.sourcePortData = []
      this.targetPortData = []
      this.sourceUsagePorts = []
      this.targetUsagePorts = []

      this.sourceParams = {
        page: 1,
        page_size: 10
      }
      this.targetParams = {
        page: 1,
        page_size: 10
      }
      this.sourcePortTotal = 0
      this.targetPortTotal = 0
      this.visible = false
    },
    popupScroll (type) {
      if (type === 'source') {
        this.sourceParams.page++
        this.fetchSourcePorts()
      } else if (type === 'target') {
        this.targetParams.page++
        this.fetchTargetPorts()
      }
    },
    handleSearch (arr) {
      if (arr[1] === 'source') {
        this.sourcePortData = []
        this.sourceParams = {
          page: 1,
          page_size: 10
        }
        if (arr[0]) this.sourceParams.name = arr[0]
        this.fetchSourcePorts(true)
      } else if (arr[1] === 'target') {
        this.targetPortData = []
        this.targetParams = {
          page: 1,
          page_size: 10
        }
        if (arr[0]) this.targetParams.name = arr[0]
        this.fetchTargetPorts(true)
      }
    },
    fetchSourcePorts (isSearch = false) {
      const getFunc = this.getFunc(this.sourceNode.data.sourceType)
      if (getFunc) {
        this.sourceSelectLoading = true
        getFunc(
          this.sourceNode.data.sourceId,
          this.sourceParams
        ).then(res => {
          this.sourcePortTotal = res.data.total
          const ports = []

          res.data.data.forEach(item => {
          // 先判断是否已经添加了
            const index = this.sourcePortData.findIndex(e => e.id === item.id)
            if (index !== -1) this.sourcePortData.splice(index, 1)
            const port = this.sourceNode.getPort(item.id)
            ports.push({
              id: item.id,
              name: item.name,
              disabled: port ? port.disabled : false
            })
          })
          this.sourcePortData.push(...ports)
          this.sourceSelectLoading = false
        })
      }
    },
    fetchTargetPorts (isSearch = false) {
      const getFunc = this.getFunc(this.targetNode.data.sourceType)
      if (getFunc) {
        this.targetSelectLoading = true
        getFunc(
          this.targetNode.data.sourceId,
          this.targetParams
        ).then(res => {
          this.targetPortTotal = res.data.total
          const ports = []

          res.data.data.forEach(item => {
            const index = this.targetPortData.findIndex(e => e.id === item.id)
            if (index !== -1) this.targetPortData.splice(index, 1)
            const port = this.targetNode.getPort(item.id)
            ports.push({
              id: item.id,
              name: item.name,
              disabled: port ? port.disabled : false
            })
          })
          this.targetPortData.push(...ports)
          this.targetSelectLoading = false
        })
      }
    },
    getFunc (type) {
      if (type === 'network_device') return getNetworkDeviceNetworkInterfaceList
      if (type === 'server') return getServerInterfaceList
      if (type === 'storage') return getStorageInterfaceList
      if (type === 'hypervisor') return null
    }
  }
}
</script>

<style>

</style>
