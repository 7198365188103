<template>
  <a-modal
    :destroy-on-close="true"
    :title="$t(`source_type.${sourceType}`)"
    :visible="visible"
    :width="1200"
    @cancel="visible = false"
    @ok="handleOk"
  >
    <network-device-table
      v-if="sourceType === 'network_device'"
      :can-operate="false"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      :fetchParams="{device_type: deviceType}"
      @selectedData="getSelectedData"
    ></network-device-table>
    <storage-table
      v-else-if="sourceType === 'storage'"
      :can-operate="false"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></storage-table>
    <server-table
      v-else-if="sourceType === 'server'"
      :can-operate="false"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></server-table>
    <os-table
      v-else-if="sourceType === 'os'"
      :can-operate="false"
      :default-selected="selectedData"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></os-table>
    <middleware-table
      v-else-if="sourceType === 'middleware'"
      :can-operate="false"
      :default-selected="selectedData"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></middleware-table>
    <database-table
      v-else-if="sourceType === 'database'"
      :can-operate="false"
      :default-selected="selectedData"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></database-table>
    <site-table
      v-else-if="sourceType === 'site'"
      :default-selected="selectedData"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></site-table>
    <domain-table
      v-else-if="sourceType === 'domain'"
      :can-operate="false"
      :default-selected="selectedData"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></domain-table>
    <ssl-certificate-table
      v-else-if="sourceType === 'ssl_certificate'"
      :can-operate="false"
      :default-selected="selectedData"
      :in-modal="true"
      :table-operation-width="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></ssl-certificate-table>
    <application-table
      v-else-if="sourceType === 'application'"
      :default-selected="selectedData"
      :in-modal="true"
      :tableOperationWidth="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></application-table>
    <hypervisor-table
      v-else-if="sourceType === 'hypervisor'"
      :default-selected="selectedData"
      :in-modal="true"
      :tableOperationWidth="0"
      :tools-list="['reload', 'search']"
      @selectedData="getSelectedData"
    ></hypervisor-table>
  </a-modal>
</template>

<script>
import NetworkDeviceTable from '@/components/table/NetworkDeviceTable'
import StorageTable from '@/components/table/StorageTable'
import ServerTable from '@/components/table/ServerTable'
import OSTable from '@/components/table/OSTable'
import MiddlewareTable from '@/components/table/MiddlewareTable'
import DatabaseTable from '@/components/table/DatabaseTable'
import ApplicationTable from '@/components/table/ApplicationTable'
import SiteTable from '@/components/table/SiteTable'
import DomainTable from '@/components/table/DomainTable'
import SSLCertificateTable from '@/components/table/SSLCertificateTable'
import HypervisorTable from '@/components/table/HypervisorTable'
// import { networkTopologyPortsGroup } from '@/utils/topology'

export default {
  name: 'SourceSelectModal',
  components: {
    ApplicationTable,
    DatabaseTable,
    DomainTable,
    MiddlewareTable,
    NetworkDeviceTable,
    StorageTable,
    ServerTable,
    'os-table': OSTable,
    SiteTable,
    'ssl-certificate-table': SSLCertificateTable,
    HypervisorTable
  },
  data () {
    return {
      visible: false,
      selectedData: [],
      tableSelectedData: [],
      sourceType: '',
      deviceType: undefined,
      node: undefined
    }
  },
  methods: {
    show (node) {
      this.node = node
      this.sourceType = node.data.sourceType
      this.deviceType = node.data.deviceType
      this.visible = true
    },
    handleOk () {
      const nodes = []
      this.selectedData = this.tableSelectedData

      // 选择节点
      for (let i = 0; i < this.selectedData.length; i++) {
        const row = this.selectedData[i]
        const node = JSON.parse(JSON.stringify(this.node))
        const { x, y } = node.position
        node.data.name = row.label
        node.data.sourceId = row.key
        node.id = row.key
        node.position = { x: x + 70 * i, y }
        node.ports = []
        nodes.push(node)
      }
      this.$emit(
        'ok',
        nodes
      )
      this.visible = false
    },
    getSelectedData (data) {
      this.tableSelectedData = data
    }
  }
}
</script>

<style>

</style>
