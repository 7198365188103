var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sourceNode && _vm.targetNode)?_c('a-modal',{attrs:{"title":"链路创建","visible":_vm.visible,"width":720,"destroy-on-close":true},on:{"ok":_vm.ok,"cancel":_vm.reset}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-alert',{staticStyle:{"border-radius":"8px","margin-bottom":"16px"},attrs:{"message":"不选择网口默认连接到设备上。","banner":"","type":"info"}}),_c('a-form-model',{ref:"form",attrs:{"label-col":{ span: 3, style: { textAlign: 'left' } },"model":_vm.form,"wrapper-col":{ span: 21 }}},[_c('a-form-model-item',{staticStyle:{"margin-bottom":"8px"},attrs:{"label":"网口1","prop":"sourcePort"}},[_c('network-interface-select',{attrs:{"dataSource":_vm.sourcePortData,"nodeType":"source","form":_vm.sourceParams,"total":_vm.sourcePortTotal,"showSearch":true,"loading":_vm.sourceSelectLoading},on:{"popupScroll":function($event){return _vm.popupScroll('source')},"search":_vm.handleSearch,"clearSearch":() => {
              _vm.sourceParams = {
                page: 1,
                page_size: 10
              }
              _vm.fetchSourcePorts()
              _vm.sourcePortData = []
            }},model:{value:(_vm.form.sourcePort),callback:function ($$v) {_vm.$set(_vm.form, "sourcePort", $$v)},expression:"form.sourcePort"}}),_c('div',{staticStyle:{"font-size":"12px","color":"#585858","margin":"-6px 0 0 2px"}},[_vm._v("设备名称："+_vm._s(_vm.sourceNode.data.name))])],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"8px"},attrs:{"label":"网口2","prop":"targetPort"}},[_c('network-interface-select',{attrs:{"dataSource":_vm.targetPortData,"nodeType":"target","form":_vm.targetParams,"total":_vm.targetPortTotal,"showSearch":true,"loading":_vm.targetSelectLoading},on:{"popupScroll":function($event){return _vm.popupScroll('target')},"search":_vm.handleSearch,"clearSearch":() => {
              _vm.targetParams = {
                page: 1,
                page_size: 10
              }
              _vm.fetchTargetPorts()
              _vm.targetPortData = []
            }},model:{value:(_vm.form.targetPort),callback:function ($$v) {_vm.$set(_vm.form, "targetPort", $$v)},expression:"form.targetPort"}}),_c('div',{staticStyle:{"font-size":"12px","color":"#585858","margin":"-6px 0 0 2px"}},[_vm._v("设备名称："+_vm._s(_vm.targetNode.data.name))])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }